import * as React from 'react'

interface Props {
  className?: string
}

export default function VideoLike({ className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 1.000000 0" />
        </filter>
      </defs>
      <g filter="url(#prefix__a)" transform="translate(-54 -2)" fill="none" fillRule="evenodd">
        <path d="M54 2h14v14H54z" />
        <path
          className={className}
          fill="#FFF"
          fillRule="nonzero"
          d="M57.172 6.171a2.5 2.5 0 013.535 0l.292.294.294-.294a2.5 2.5 0 013.535 3.536l-.293.293.06.061-3.535 3.536-.06-.061-.06.06-3.536-3.535.06-.06-.293-.293a2.5 2.5 0 010-3.536zm.707.708a1.5 1.5 0 00-.096 2.014l.098.108.995.999-.061.058 2.121 2.121.055-.054.063.063 2.122-2.121-.062-.062L64.121 9a1.5 1.5 0 00-2.014-2.218L62 6.879l-1.001 1-.999-1a1.5 1.5 0 00-2.121 0z"
        />
      </g>
    </svg>
  )
}

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as streamingActions } from '../modules/streaming'
import { CheckBox } from '../components/CheckBox';
import { GoodsInfo } from './live';
import { StoreState } from '../modules';

interface ViewerItemProps {
  id: string
  goods: GoodsInfo
  isCheckList?: boolean
  checked: boolean
}

function GoodsItem ({id, goods, checked, isCheckList} : ViewerItemProps) {
  const dispatch = useDispatch();

  const onChange = (id: string, checked: boolean): void => {
    if (checked) {
      dispatch(streamingActions.checkedGoods(goods))
    } else {
      dispatch(streamingActions.uncheckedGoods(goods))
    }
  }

  return (
    <div className="goods-info-group">
      <img className="goods-thumbnail" src={goods.thumbnailUrl} alt="goods_thumbnail" />
      <div className="goods-content">
        <div className="goods-title text-ellipsis">{goods.title}</div>
        <div className="goods-price">{goods.price} 원</div>
      </div>
      {isCheckList && <span className="goods-checkbox">
        <CheckBox id={id} value={id} checked={checked} onChange={onChange} />
      </span>}
    </div>
  )
}

interface Props {
  goodsList: GoodsInfo[]
  isCheckList?: boolean 
}

export default function GoodsList({ goodsList, isCheckList }: Props): JSX.Element {
  const { checkList } = useSelector((state: StoreState) => ({checkList: state.streaming.goodsList}));

  return (
    <div className="goods-list-group">
      {goodsList.length > 0 && goodsList.map((goods, i) => 
        <GoodsItem key={i} id={`${goods.id}`} isCheckList={isCheckList} goods={goods} checked={checkList.findIndex(c => c.id === goods.id) >= 0}/>
      )}
      <div className="goods-info-group"></div>
    </div>
  )
}
import * as React from 'react'

interface Props {
  className?: string
}

export default function VideoView({ className }: Props): JSX.Element {
  switch (className) {
    case 'white-svg-icon':
      return (
        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="12acx">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-23 -13)" filter="url(#12acx)" fill="none" fillRule="evenodd">
            <path d="M23 13h14v14H23z" />
            <g className={className} fill="#FFF" fillRule="nonzero">
              <path d="M29.917 16c-2.665 0-4.643 1.278-5.866 3.78l-.108.22.108.22c1.223 2.502 3.2 3.78 5.866 3.78 2.665 0 4.643-1.278 5.866-3.78l.107-.22-.107-.22C34.56 17.278 32.582 16 29.917 16zm.26 1.005c1.97.072 3.438.97 4.453 2.735l.142.259-.142.261C33.57 22.1 32.018 23 29.917 23l-.26-.005c-1.97-.072-3.44-.97-4.453-2.735L25.06 20l.144-.26C26.262 17.9 27.815 17 29.917 17l.26.005z" />
              <path d="M29.917 17.47a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
            </g>
          </g>
        </svg>
      )
    case 'orange-svg-icon':
      return (
        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="aaasdf">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.976471 0 0 0 0 0.388235 0 0 0 0 0.003922 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-23 -13)" filter="url(#aaasdf)" fill="none" fillRule="evenodd">
            <path d="M23 13h14v14H23z" />
            <g className={className} fill="#FFF" fillRule="nonzero">
              <path d="M29.917 16c-2.665 0-4.643 1.278-5.866 3.78l-.108.22.108.22c1.223 2.502 3.2 3.78 5.866 3.78 2.665 0 4.643-1.278 5.866-3.78l.107-.22-.107-.22C34.56 17.278 32.582 16 29.917 16zm.26 1.005c1.97.072 3.438.97 4.453 2.735l.142.259-.142.261C33.57 22.1 32.018 23 29.917 23l-.26-.005c-1.97-.072-3.44-.97-4.453-2.735L25.06 20l.144-.26C26.262 17.9 27.815 17 29.917 17l.26.005z" />
              <path d="M29.917 17.47a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0 1a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
            </g>
          </g>
        </svg>
      )
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14}>
          <defs>
            <filter id="prefa123">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 0 0.533333 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g filter="url(#prefa123)" transform="translate(0 -2)" fill="none" fillRule="evenodd">
            <path d="M0 2h14v14H0z" />
            <g className={className} fill="#FFF" fillRule="nonzero">
              <path d="M6.917 5C4.252 5 2.274 6.278 1.05 8.78L.942 9l.108.22C2.274 11.722 4.25 13 6.917 13c2.665 0 4.643-1.278 5.866-3.78L12.89 9l-.107-.22C11.56 6.278 9.582 5 6.917 5zm.26 1.005c1.97.072 3.438.97 4.453 2.735l.142.259-.142.261C10.57 11.1 9.018 12 6.917 12l-.26-.005c-1.97-.072-3.44-.97-4.453-2.735L2.06 9l.144-.26C3.262 6.9 4.815 6 6.917 6l.26.005z" />
              <path d="M6.917 6.47a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 1a1.5 1.5 0 11.001 2.999 1.5 1.5 0 01-.001-2.999z" />
            </g>
          </g>
        </svg>
      )
  }
}

import * as React from 'react'
import { MessageInfo, SystemMessageType } from './live'

const MessaegProvider = (message: MessageInfo | SystemMessageType) => {
  switch (message.messageType) {
    case "MESSAGE":
      if(message.customType === "MANAGER") {
        return <ManagerMessage message={message} username={"관리자"}/>
      }
      
      return <NormalMessage message={message} />
    case "DM":
      return <DirectMessage message={message} />
    case "ADMIN": // UPDATE, ACTIVE, INACTIVE
      if(!message.customType) {
        return <AdminMessage message={message} username={"관리자"} />
      }
      return null;
    case "PENDING":
      return <PendingMessage message={message} />
    default:
      return <SystemMessage message={message} />
  }
}

interface PendingMessageProps {
  message: MessageInfo | SystemMessageType
}

const PendingMessage = ({message}: PendingMessageProps) => (
  <div className="list-group-item pending-message">
    <div className="bmd-list-group-col">
      <p className="list-group-item-text">{message.message}</p>
    </div>
  </div>
)

interface SystemMessageProps {
  message: MessageInfo | SystemMessageType
  username?: string | null
}

const SystemMessage = ({message}: SystemMessageProps) => (
  <div className="list-group-item system-message">
    <div className="bmd-list-group-col">
      <p className="list-group-item-text">{message.message}</p>
    </div>
  </div>
)

interface AdminMessageProps {
  message: MessageInfo
  username?: string | null
}

const AdminMessage = ({message, username}: AdminMessageProps) => (
  <div className="list-group-item admin-message">
    <div className="bmd-list-group-col">
      <div className="message-user">{username || message.user.username}</div>
      <div className="list-group-item-text">
        <div className="message-content">{message.message}</div>
      </div>
    </div>
  </div>
)

interface NormalMessageProps {
  message: MessageInfo
  username?: string | null
}

const NormalMessage = ({message, username}: NormalMessageProps) => (
  <div className="list-group-item normal-message">
    <div className="bmd-list-group-col">
      <div className="message-user">{username || message.user.username}</div>
      <div className="list-group-item-text">
        <div className="message-content">{message.message}</div>
      </div>
    </div>
  </div>
)

const ManagerMessage = ({message, username}: NormalMessageProps) => (
  <div className="list-group-item manager-message">
    <div className="bmd-list-group-col">
      <div className="message-user">{username || message.user.username}</div>
      <div className="list-group-item-text">
        <div className="message-content">{message.message}</div>
      </div>
    </div>
  </div>
)

interface DirectMessageProps {
  message: MessageInfo
  username?: string | null
}

const DirectMessage = ({message, username}: DirectMessageProps) => (
  <div className="list-group-item">
    <div className="bmd-list-group-col">
      <div className="list-group-item-text direct-message">
      { username || message.user && message.user.username ? 
        <div className="message-user text-ellipsis">{`${message.user.username}`}<span className="from-now">{message.createdAtFormat}</span></div> : 
        <div className="message-user">noname <span className="from-now">{message.createdAtFormat}</span></div> }
        <div className="message-content">{message.message}</div>
      </div>
    </div>
  </div>
)

interface Props {
  message: MessageInfo | SystemMessageType
}

class ChatItem extends React.Component<Props> {
  
  render(): JSX.Element | null {
    const { message } = this.props

    return (
        MessaegProvider(message)
    )
  }
}


export default ChatItem
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { useCallback, useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { isClient } from '../../utils/browser';
import Button from '../button/Button';

interface Props {
  visible: boolean;
  setVisible?: (visible: boolean) => void;
  onOk?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  render?: ReactNode | string;
  disabled?: boolean | number
  title?: string;
  okButtonTitle?: string;
  cancelButtonTitle?: string;
  className?: string
  noHeader?: boolean
}

export default function Modal({
  visible,
  setVisible,
  onOk,
  onCancel,
  onClose,
  disabled,
  render,
  title,
  className,
  okButtonTitle,
  cancelButtonTitle,
  noHeader
}: Props) {
  const toggle = useCallback(() => {
    if(disabled) {
      return;
    }
    setVisible && setVisible(!visible);
  }, [setVisible, visible, disabled]);

  const noneEvent = useCallback((e) => e.stopPropagation(), []);

  const escapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggle();
      }
    },
    [toggle],
  );

  const clickCancel = useCallback(() => {
    toggle();
    if (onCancel) onCancel();
    if (onClose) onClose();
  }, [toggle, onCancel, ]);

  const clickOk = useCallback(() => {
    toggle();
    if (onOk) onOk();
  }, [toggle, onOk]);

  useEffect(() => {
    const root = document.querySelector("#root")

    if(!root) {
      return;
    }
    
    root.classList.remove('modal-showing')
    visible && root.classList.add('modal-showing')
  }, [visible])

  useEffect(() => {
    if (visible) window.addEventListener('keydown', escapeKey);
    else if (!visible) window.removeEventListener('keydown', escapeKey);
    return () => {
      window.removeEventListener('keydown', escapeKey);
    };
  }, [escapeKey, visible]);

  return isClient && visible
    ? createPortal(
        <>
          <div className="overlay" />
          <div onClick={toggle} className={className + " modal-container"}>
            <div onClick={noneEvent} className="modal-wrap">
              {!noHeader && <div className="modal-header">
                <div className="modal-title">{title}</div>
                <div className={"close-icon" + (disabled ? " disable" : "")} onClick={clickCancel} />
              </div>}
              <div className="content-wrap">{render}</div>
              <div className="button-wrap">
                {onCancel && (
                  <Button size="large" color="white" onClick={clickCancel}>
                    {cancelButtonTitle || 'Cancel'}
                  </Button>
                )}
                {onOk && (
                  <Button size="large" color="red" onClick={clickOk}>{okButtonTitle || 'Ok'}</Button>
                )}
              </div>
            </div>
          </div>
        </>,
        document.body,
      )
    : null;
}
import * as React from 'react';
import { Component } from 'react';
import { NavItem } from '../components/Navigation';
import DetailChatForm from './DetailChatForm'

interface Props {
  stage: number
  messages: any[]
  sendMessage?(m: string): void
  handleStage?(stage: number): void
}

export default class MonitorChatBox extends Component<Props> {

  render (): JSX.Element {
    const { stage } = this.props

    return (
      <div className="monitor-chatbox-group">
        <div className="tab-group">
          <ul className="nav nav-pills">
            <NavItem stage={1} value={0} label={"채팅 메시지"} handleChange={this.props.handleStage?.bind(this, 0)} />
          </ul>
        </div>
        <div className="monitor-chatbox-content">
          { stage === 0 ? 
            this.props.sendMessage && <DetailChatForm messages={this.props.messages} sendMessage={this.props.sendMessage} /> :
            ""
          }
        </div>
      </div>
    )
  }
}
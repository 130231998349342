import * as React from 'react';
import * as moment from 'moment';
import videojs from 'video.js';
import { connect } from 'react-redux';
import Modal from '../../../../components/modal/Modal';
import Toast from '../../../../components/Toast';
import { throttle } from 'lodash';
import { getDemoLiveStat, getDemoVideo, getDemoWatchersHistory, GoodsInfo, LiveStatInfo, Message, MessageInfo, VideoInfo, WatcherHistoryInfo } from '../../../../live/live';
import { Config, Messages } from '../../../../utils';
import { secondsToTime } from '../../../../utils/Format';
import { LiveBadge, VideoLike, VideoView } from '../../../../components/images/svg';
import StreamSubBox from '../../../../live/StreamSubBox';
import MonitorChatBox from '../../../../live/MonitorChatBox';
import MonitorChartBox from '../../../../live/MonitorChartBox';
import MonitorLiveStat from '../../../../live/MonitorLiveStat';
import { StoreState } from '../../../../modules';
import { navigate } from 'gatsby-link';
import { isClient } from '../../../../utils/browser';

interface Props {
  player: videojs.Player
}

interface State {
  video: VideoInfo | null
  goods: GoodsInfo[]
  liveStat: LiveStatInfo
  chartData: WatcherHistoryInfo
  messages: MessageInfo[]
  username: string
  duration: string
  videoLike: number
  videoView: number
  toggleModal: boolean
  player: videojs.Player
}

class DemoDetailViewerContainer extends React.Component<Props, State> {
  state: State = {
    video: null,
    goods: [],
    liveStat: new LiveStatInfo({max_watcher_count: 0, total_message_count: 0, total_watcher_count: 0, avg_live_watch_time: 0}),
    chartData: new WatcherHistoryInfo({count_list: [], time_list: []}),
    messages: [],
    username: '',
    duration: '00:00',
    videoLike: 0,
    videoView: 0,
    toggleModal: false,
    player: null
  }

  videoNode: any
  gossip: any
  player: any
  sdk: any

  loadApplication = (): void => {
    if(isClient) {
      const videoKey = isClient && new URLSearchParams(window.location.search).get("id");

      if(!videoKey) {
        Toast.warn("video key are reqired")
        navigate("/demo")
        return;
      }

      const appKey = Config.DEMO_APP_KEY
  
      getDemoVideo(appKey, videoKey, this.setVideo)
      this.loadStatData(appKey, videoKey)
    }
  }

  loadStatData = (appKey: string, videoKey: string) => {
    getDemoLiveStat(appKey, videoKey, this.setLiveStat)
    getDemoWatchersHistory(videoKey, this.setChartData);
  }

  initToken = (video: VideoInfo): void => {
    console.log(video)
    this.initMonitor(video)
  }

  initMonitor = async (video: VideoInfo): Promise<void> => {
    const { FlipFlop } = (await import('flipflop-sdk-javascript/dist/flipflop'))
    const appKey = Config.DEMO_APP_KEY
    const appSecret = Config.DEMO_APP_SECRET
    const username = localStorage.getItem(Config.DEMO_USERNAME_KEY)

    if(!username) {
      Toast.warn("channal and username are reqired")
      navigate("/demo")
      return;
    }

    const profilePhoto = ""
    
    console.log(`appKey: ${appKey}, appSecret: ${appSecret}`);
    console.log(`userId: ${username}, username: ${username}, profilePhoto - ${profilePhoto}`);

    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(username, username, profilePhoto);
        
    this.player = this.sdk.getPlayer(video.videoKey);
    this.player.prepare(this);

    const params = {
      timestamp: video.createdAt,
      message_types: "MESSAGE, ADMIN",
      prev_size: Config.MESSAGE_COUNT,
      is_inclusive: 0,
    }

    if (this.player) {
      const video = this.player.start(true)
      
      console.log("get video", video)

      this.player.getChatMessages(params)
    }
  }
  
  setVideo = (video: VideoInfo,  goods: GoodsInfo[]) => {
    if(video.state === "VOD") {
      window.location.href = `/demo`;
      return;
    }

    this.setState({ video, goods })

    if(video) {
      this.initToken(video)
    }
  }

  setLiveStat = (liveStat: LiveStatInfo) => {
    this.setState({ liveStat })
  }

  setChartData = (chartData: WatcherHistoryInfo) => {
    this.setState({ chartData })
  }

  setMessages = (messages: MessageInfo[]): void => {
    this.setState({ messages })
  }
  
  onPrepare = (): void => {
    console.log('onPrepare')
  }

  onStart = (): void => {
    console.log('onStart')
  }

  onStop = (): void => {
    console.log(`onStopped`)
  }

  onCompleted = () => {
    console.log(`onCompleted`)
  }

  onError = (error: string) => {
    if (error){
      console.error(error)
    }
  }

  onChatReceived = (message: Message): void => {
    console.log("onChatReceived", message)
    message.elapsedTime = Date.now() - message.created_at

    if (message.message_type ==="MESSAGE" || (message.message_type ==="ADMIN" && message.custom_type ==="")) {
      const { liveStat } = this.state;
      liveStat.totalMessageCount++;
      this.setState({liveStat})
    }
    
    const { messages } = this.state;
    console.log("message.message_type", message.message_type)
    if(message.message_type === "JOIN" || message.message_type === "LEAVE") {
      this.state.video instanceof VideoInfo && this.loadStatData(this.state.video.appKey, this.state.video.videoKey)
      this.setState({ messages: messages.concat(new MessageInfo(message)), videoView: message?.total_user_count || 0});
    } else {
      this.setState({ messages: messages.concat(new MessageInfo(message))});
    }
    if(message.custom_type === "UPDATE") {
      const data = JSON.parse(message?.data || "");
      if(data?.like_count) {
        this.setState({videoLike: data.like_count});
      }
    }

    if(message.custom_type === "CLOSE" || message.custom_type === "DISCOUNT") {
      this.endLive();
      return;
    }
  }

  onTimeUpdate = throttle((): void => {
    if(this.state.video instanceof VideoInfo) {
      const diffTime = secondsToTime((moment().unix() - moment(this.state.video.createdAt).unix()))
      this.setState({ duration: diffTime });
    }
  }, 1000)

  endChat = () => {
    if (this.gossip) {
      this.gossip.disconnect()
    }
  }

  endLive = () => {
    this.setState({toggleModal: true})
  }

  endVideo = () => {
    const { player } = this.state
    if(player) {
      player.reset();
    }
  }

  componentDidMount () { 
    this.loadApplication()
  }

  componentWillUnmount() {
    this.endChat();
    this.endVideo();
  }

  render(): JSX.Element | null {
    const { messages, video, chartData, liveStat } = this.state

    if(!video) {
      return null;
    }

    return (
      <div className="monitor-container streaming-monitor demo-detail-viewer-conatiner">
        <div className="monitor-content">
          <div className="monitor-header">
            <div>
              <div className="monitor-title-group">
                <h3 className="monitor-title">{this.state.video?.title || "Untitled"}</h3>
              </div>
              <div className="monitor-desc">{this.state.video?.content || "No Content"}</div>
            </div>
          </div>
            
          <div className="monitor-item-list">
            <div className="monitor-item">
                <div className="video-player-container">
                  <div className="video-player-group">
                    <span className="badge no-padding"><LiveBadge /></span>
                    <video id="screenvideo" ref={node => this.videoNode = node } autoPlay={true} playsInline onTimeUpdate={this.onTimeUpdate}/>
                    
                    {video instanceof VideoInfo &&
                    <div className="video-info-overlay">
                      <div className="duration-overlay">
                        {this.state.duration}
                        </div>
                        <div className="inject-overlay">
                          WEBRTC
                        </div>
                      </div>
                      }  
                  </div>
                  <div className="video-player-footer">
                    <div>
                      <img className="avatar-thumbnail" src={video instanceof VideoInfo ? video.user.profilePhotoUrl || Config.DEFAULT_AVATAR : Config.DEFAULT_AVATAR} />
                      <span>{video.user.username}</span>
                    </div>
                    <div className="icon-group">
                      <div className="icon-item"><VideoView /><span>&nbsp;{this.state.videoView}&nbsp;</span>•&nbsp;</div>
                      <div className="icon-item"><VideoLike /><span>&nbsp;{this.state.videoLike}&nbsp;</span>•&nbsp;</div>
                      <div className="icon-item"><span>Guest&nbsp;:&nbsp;{this.state.liveStat.guestWatcherCount || 0}&nbsp;</span></div>
                    </div>
                  </div>
                </div>
              <StreamSubBox goodsList={this.state.goods} isCheckList={false} />
            </div>
            <div className="monitor-item">
              {video && <MonitorChatBox sendMessage={() => ({})} messages={messages} stage={0}/>}
            </div>
            <div className="monitor-item">
              <MonitorChartBox chartData={chartData} messages={messages} />
              <MonitorLiveStat liveStat={liveStat}/>
            </div>
          </div>
        </div>
        <Modal className="demo-modal" visible={this.state.toggleModal} onOk={() => {window.location.href = '/demo'}} disabled render={<p className="landing-description">{Messages.LIVE_DEMO_CONTENTS.endDesciption}</p>} title={"라이브 종료"} />
      </div>
    )
  }
}

export default connect(
	({ streaming } : StoreState) => ({
    player: streaming.player,
  }),
	() => ({
  })
)(DemoDetailViewerContainer)
import * as React from 'react'

interface Props {
  className?: string
}

export default function Send({className}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0H14.215V14H0z" transform="translate(-1131 -869) translate(657 70) translate(436 786) translate(38.585 13)" />
                <path className={className} fill="#FFF" d="M1.015 1L13.2 7 1.015 13V8.333L9.138 7 1.015 5.666V1z" transform="translate(-1131 -869) translate(657 70) translate(436 786) translate(38.585 13)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

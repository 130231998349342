import * as React from 'react'

export default function LiveBadge(): JSX.Element {
  return (
    <svg width={39} height={16} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M31 .5c2.071 0 3.946.84 5.303 2.197A7.477 7.477 0 0138.5 8c0 2.071-.84 3.946-2.197 5.303A7.477 7.477 0 0131 15.5H8a7.477 7.477 0 01-5.303-2.197A7.477 7.477 0 01.5 8c0-2.071.84-3.946 2.197-5.303A7.477 7.477 0 018 .5z" stroke="#D5120C" fill="#D5120C" />
        <circle fill="#FFF" cx={31.5} cy={5.5} r={1.5} />
        <text fontFamily="NotoSansCJKkr, sans-serif" fontSize={10} fontWeight="bold" fill="#FFF">
          <tspan x={6} y={12}>
            {'LIVE'}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

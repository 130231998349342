import * as React from 'react'

interface Props {
  className?: string
}
export default function ArrowDown({ className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" className={className}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H12V12H0z" transform="translate(-1508 -34) translate(1382 30) rotate(-180 69 8)" />
              <path fill="#252525" d="M6 4L10 9 2 9z" transform="translate(-1508 -34) translate(1382 30) rotate(-180 69 8) rotate(-180 6 6.5)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

import * as React from 'react';
import { Component } from 'react'
import { NavItem } from '../components/Navigation';
import { Config } from '../utils';
import { GoodsInfo } from './live';
import GoodsList from './GoodsList'

interface Props {
  goodsList: GoodsInfo[]
  isCheckList?: boolean
}


export default class StreamSubBox extends Component<Props> {
  render(): JSX.Element {
    return (
      <div className="monitor-subbox-group">
        <div className="tab-group">
          <ul className="nav nav-pills">
            <NavItem label={'상품 리스트'} value={0} stage={1}/>
          </ul>
          <span className="goods-selected-count">
            <b>{this.props.goodsList.length}</b>
            <span>{`/${Config.SAMPLE_GOODS.length}`}</span>
          </span>
        </div>
        <div className="monitor-subbox-content">
          <GoodsList goodsList={this.props.isCheckList ? Config.SAMPLE_GOODS : this.props.goodsList} isCheckList={this.props.isCheckList}/>
        </div>
      </div>
    )
  }
}
import * as React from 'react';
import FormBullet from '../components/images/svg/FormBullet';
import { currencyFormat } from '../utils/Format';
import { LiveStatInfo } from './live';

interface LiveStatItemProps {
  title: string
  value: string
  unit: string
}

function LiveStatItem ({title, value, unit}: LiveStatItemProps) {
  return (
    <div className="vod-stat-item-group">
      <div className="vod-stat-item-title"><FormBullet />{title}</div>
      <div className="vod-stat-item-content"><span>{value}</span><span>{unit}</span></div>
    </div>
  )
}

interface Props {
  liveStat: LiveStatInfo
}

export default function MonitorLiveStat({ liveStat }: Props): JSX.Element {
  return (
    <div className="monitor-vod-stat-group">
      <LiveStatItem title="총 사용자수" value={currencyFormat(liveStat?.totalWatcherCount)} unit="명" />
      <LiveStatItem title="최대 동시 시청자수" value={currencyFormat(liveStat?.maxWatcherCount)} unit="명" />
      <LiveStatItem title="현재 전체 메시지수" value={currencyFormat(liveStat?.totalMessageCount)} unit="개" />
    </div>
  )
}